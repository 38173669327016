<template>
  <b-tabs content-class="pt-2" justified>
    <b-tab title="Notes internes">
      <b-card-text>
        <notes-internes :reclamation="reclamation" />
      </b-card-text>
    </b-tab>
    
    <b-tab title="Documents">
      <b-card-text>
        <documents :reclamation="reclamation"/>
      </b-card-text>
    </b-tab>
   
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText, BButton } from 'bootstrap-vue'
import NotesInternes from '@/views/portefeuilles/reclamations/detail_fiche_reclamation/NotesInternes.vue'
import Documents from '@/views/portefeuilles/reclamations/detail_fiche_reclamation/Documents.vue'
export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    BButton,
    NotesInternes,
    Documents
    
  },
  props: {
    reclamation: {
      type: Object,
      default: {
        assureur: null,
        assureur_id: null,
        canal_reclamtion: null,
        client: null,
        comment: null,
        created_at: null,
        date_acc_recep: null,
        date_de_cloture: null,
        date_de_réclamation: null,
        declarant: null,
        etat: null,
        id: null,
        issue: null,
        mesure_cor: null,
        motif: null,
        niveau: null,
        num_reclamation: null,
        reclamable_id: null,
        reclamable_type: null,
        reclamation_client: null,
        reponse_app: null,
        service_concerne: null,
        statut: null,
      },
      required: true,
    },
  },
  data() {
    return {
     
      }
    }
  ,
  watch: {
    
  },
  methods: {
    
  },
  setup() {
    
  }
}
</script>
